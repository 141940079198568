import React from 'react'
import PropTypes from 'lasagna/prop_types'
import { View, Text, TouchableOpacity, Image, Platform } from 'react-native'
import Card from 'lasagna/components/layout/card'
import Icon from 'lasagna/components/icon'
import styled from 'styled-components/native'
import { observer } from 'mobx-react'
import { COLOR_BLACK, COLOR_BACKGROUND_DARK, COLOR_ACTION } from '../../constants/layout'
import { SERVICE_TYPES } from '../../constants/service_types'
import openExternal from '../../helpers/navigation/open_external'
import { pdfPath } from '../../helpers/urls'
import Status from './status'

const InvoiceCard = styled(Card)`
  margin-bottom: 8px;
  padding: 20px;
`

const Row = styled(View)`
  flex-direction: row;
  align-items: center;
  ${({ marginTop }) => marginTop && 'margin-top: 8px;'}
`

const DownloadLinks = styled(View)`
  flex-direction: row;
  flex-shrink: 1;
  flex-wrap: wrap;
  justify-content: flex-end;
`

const InvoiceType = styled(Text)`
  margin-right: 16px;
  font-size: 16px;
  font-weight: bold;
  color: ${COLOR_BACKGROUND_DARK};
`

const Total = styled(Text)`
  color: ${COLOR_BLACK};
  font-weight: bold;
`

const ViewInvoice = styled(Total)`
  text-decoration: underline;
`

const Amount = styled(Text)`
  color: ${COLOR_BLACK};
  font-size: 14px;
  margin-left: 4px;
`

const Pdf = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  margin-left: 12px;
`

const InvoiceDate = styled(Text)`
  font-size: 16px;
  width: 150px;
`

const Address = styled(Text)`
  margin-left: 6px;
`

const ServiceType = styled(Text)`
  background-color: ${COLOR_ACTION};
  color: ${COLOR_BLACK};
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  margin-right: 8px;
`

const ServiceTypeIcon = styled(Image)`
  height: 13px;
  margin-right: 4px;
  ${({ type }) => (type === SERVICE_TYPES.carpet_cleaning.key ? 'width: 20px;' : 'width: 11px;')}
`

const InvoiceList = ({ invoices }) => {
  const viewPdf = path => openExternal(pdfPath(path), true)

  return invoices.map(
    ({
      id,
      invoiceDate,
      downloadEventsXlsxPath,
      downloadPdfPath,
      grossAmount,
      balance,
      cancelled,
      cancellation,
      facilityAddress,
      isCollective,
      isIndividual,
      isMaterial,
      serviceType,
      serviceName
    }) => (
      <View key={id}>
        <InvoiceCard elevation={1} testID='InvoiceCard'>
          <Row>
            <InvoiceDate>{invoiceDate}</InvoiceDate>
            <Status balance={balance} cancelled={cancelled} cancellation={cancellation} />
            <DownloadLinks>
              <Pdf onPress={() => viewPdf(downloadPdfPath)}>
                <Icon name='file-pdf-outline' set='MaterialCommunityIcons' color={COLOR_BLACK} size={16} />
                <ViewInvoice>{i18n.t('invoices.view')}</ViewInvoice>
              </Pdf>
              {isCollective && downloadEventsXlsxPath && (
                <Pdf onPress={() => viewPdf(downloadEventsXlsxPath)}>
                  <Icon name='file-table-outline' set='MaterialCommunityIcons' color={COLOR_BLACK} size={16} />
                  <ViewInvoice>{i18n.t('invoices.detailed_view')}</ViewInvoice>
                </Pdf>
              )}
            </DownloadLinks>
          </Row>
          <Row marginTop>
            <InvoiceType>
              {isCollective && i18n.t('invoices.collective')}
              {isIndividual && i18n.t('invoices.individual')}
              {isMaterial && i18n.t('invoices.material')}
            </InvoiceType>
            <Total>{i18n.t('invoices.total')}</Total>
            <Amount>{i18n.t('invoices.amount', { netAmount: grossAmount })}</Amount>
          </Row>
          {(isIndividual || isMaterial) && (
            <Row marginTop>
              {isIndividual && serviceType && (
                <ServiceType>
                  {SERVICE_TYPES[serviceType]?.iconBlack && (
                    <ServiceTypeIcon
                      source={
                        Platform.OS === 'web'
                          ? require(`../../${SERVICE_TYPES[serviceType].iconBlack}.svg`)
                          : require(`../../${SERVICE_TYPES[serviceType].iconBlack}.png`)
                      }
                      type={serviceType}
                    />
                  )}
                  {serviceName || i18n.t(`service_types.${serviceType}.title`)}
                </ServiceType>
              )}
              {facilityAddress && (
                <>
                  <Icon name='domain' color={COLOR_BLACK} size={16} />
                  <Address>{facilityAddress}</Address>
                </>
              )}
            </Row>
          )}
        </InvoiceCard>
      </View>
    )
  )
}

InvoiceList.propTypes = {
  serviceType: PropTypes.string.isRequired,
  startTimeObj: PropTypes.luxon
}

export default observer(InvoiceList)
