import { Platform } from 'react-native'

export const createHeaders = (headers, accessToken) => {
  // required for staging and testing
  const baseAuth = process.env.REACT_APP_API_AUTH

  return {
    ...headers,
    ...(accessToken ? { 'X-Auth-Token': accessToken } : {}),
    ...(baseAuth ? { Authorization: `Basic ${baseAuth}` } : {}),
    'X-Platform': Platform.OS
  }
}
