import React from 'react'
import PropTypes from 'lasagna/prop_types'
import { observer } from 'mobx-react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import Text from 'lasagna/components/text'
import Card from 'lasagna/components/layout/card'
import Button from '../../button'
import { COLOR_BORDER_INPUT, COLOR_BACKGROUND_DARK, COLOR_WHITE } from '../../../constants/layout'
import { useStore } from '../../../helpers/store'

const Container = styled(View)`
  width: 25%;
`

const Title = styled(Text)`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 12px;
`

const SelectedItem = styled(Text)`
  font-weight: bold;
  margin-top: 4px;
`

const SelectedCard = styled(Card)`
  padding: 0;
  margin-bottom: 16px;
`

const Section = styled(View)`
  ${({ noBorder }) =>
    !noBorder &&
    `
    border-bottom-width: 1px;
    border-bottom-color: ${COLOR_BORDER_INPUT};
  `}
  padding: 16px;
`

const Price = styled(SelectedItem)`
  color: ${COLOR_BACKGROUND_DARK};
`

const Sidebar = ({ frequency, facility, selectedPackage, handleSubmit, serviceType }) => {
  const {
    session: { currentUser }
  } = useStore()

  return (
    <Container>
      <Title>{i18n.t('new_booking.funnel.summary')}</Title>
      <SelectedCard>
        <Section>
          <Text>{i18n.t('contracts.details.recurrency.title')}</Text>
          <SelectedItem>{i18n.t('new_booking.funnel.frequency.per_week', { count: frequency }) || '-'}</SelectedItem>
        </Section>
        {!!currentUser && (
          <Section>
            <Text>{i18n.t('new_booking.funnel.service_address')}</Text>
            <SelectedItem>{facility ? facility.address : '-'}</SelectedItem>
          </Section>
        )}
        <Section>
          <Text>{i18n.t('new_booking.funnel.service_details')}</Text>
          {selectedPackage ? (
            <>
              <SelectedItem>{i18n.t(`service_types.${serviceType}.types.${selectedPackage.type}.title`)}</SelectedItem>
              <Text>{i18n.t(`service_types.${serviceType}.types.${selectedPackage.type}.description`)}</Text>
            </>
          ) : (
            '-'
          )}
        </Section>
        <Section noBorder>
          <Text>{i18n.t('new_booking.funnel.price')}</Text>
          <Price>
            {selectedPackage
              ? i18n.t('contracts.details.formatted_price', { price: selectedPackage.price / 100 })
              : '-'}
          </Price>
        </Section>
      </SelectedCard>
      <Button
        onPress={handleSubmit}
        color={COLOR_BACKGROUND_DARK}
        textColor={COLOR_WHITE}
        thin
        disabled={!frequency || !selectedPackage || (!!currentUser && !facility)}
      >
        {i18n.t('new_booking.form.send')}
      </Button>
    </Container>
  )
}

Sidebar.propTypes = {
  frequency: PropTypes.string,
  selectedPackage: PropTypes.shape({
    type: PropTypes.string,
    price: PropTypes.number
  }),
  facility: PropTypes.shape({
    address: PropTypes.string
  }),
  handleSubmit: PropTypes.func,
  serviceType: PropTypes.string
}

export default observer(Sidebar)
